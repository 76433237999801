<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Filter Diary Entries
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 max-w-3xl">
        <form
          action=""
          class="flex flex-col space-y-4"
        >
          <label>
            User

            <AppTypeAhead
              v-model="userNameTypeAhead"
              class="block border-gray-400 py-2 px-4 rounded w-full focus:outline-none focus:border-blue-400"
              name="user_id"
              :suggestion-callback="getSuggestions"
              :initial-suggestions="getSuggestions"
              data-test="filter-user"
              @value-selected="selectUser"
            />
          </label>

          <label>
            Diary contains
            <textarea
              v-model="diaryEntriesStore.filter.form.text"
              name="text"
              data-test="filter-text"
              class="form-input resize-none"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-center p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('clear')"
        >
          Clear
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="$emit('filter')"
        >
          Filter
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue';
import { useDiaryEntriesStore } from '@/store/diaryEntries';
import { nextTick, ref } from 'vue';

defineEmits<{
  (e: 'close');
  (e: 'filter');
  (e: 'clear');
}>();

const props = defineProps<{
  projectId: Id;
}>();

const diaryEntriesStore = useDiaryEntriesStore();
const userNameTypeAhead = ref(diaryEntriesStore.filter.form.name);
const suggestedUsers = ref<{ id: number; name: string }[]>([]);

const getSuggestions = async () => {
  suggestedUsers.value = await diaryEntriesStore
    .searchUsers(props.projectId, { name: userNameTypeAhead.value });

  return suggestedUsers.value.map((value) => value.name);
};

const selectUser = (index: number) => {
  nextTick(() => {
    if(suggestedUsers.value[index]) {
      diaryEntriesStore.filter.form.user_id = suggestedUsers.value[index].id;
      diaryEntriesStore.filter.form.name = suggestedUsers.value[index].name;
    }
  });
};
</script>

<style scoped></style>
