<template>
  <div class="flex justify-center h-full space-y-2.5 my-8">
    <div
      class="flex flex-col border border-gray-300 p-5
    items-center space-y-6 element-island container"
    >
      <div class="flex justify-between w-full">
        <h1 class="text-4xl">
          Diary Entries
        </h1>

        <div class="flex space-x-4">
          <button
            type="button"
            class="btn"
            :class="[diaryEntriesStore.active ? 'btn--primary-blue' : 'btn--secondary-blue']"
            @click="showFilter = true"
          >
            Filter Entries
          </button>

          <button
            type="button"
            class="btn btn--secondary-blue"
            @click="newEntry"
          >
            Add Entry
          </button>
        </div>
      </div>

      <div class="flex w-full space-x-4 h-full">
        <div class="w-1/2 border border-gray-300 shadow-md rounded relative flex flex-col">
          <h2 class="text-xl font-semibold pt-4 px-4">
            Raised Entries
          </h2>

          <div
            v-if="raisedDiaryEntries.length"
            class="overflow-y-auto relative flex-1"
          >
            <div class="absolute inset-0 overflow-y-auto p-4">
              <ul class="space-y-4">
                <li
                  v-for="diaryEntry in raisedDiaryEntries"
                  :key="diaryEntry.id"
                  class="cursor-pointer"
                  @click="selectEntry(diaryEntry.id)"
                >
                  <div class="flex flex-col">
                    <h3 class="font-semibold text-lg">
                      {{ diaryEntry.createdAt.toLocaleString() }} - {{ diaryEntry.user?.name }}
                    </h3>

                    <p class="line-clamp-3 whitespace-pre">
                      {{ diaryEntry.body }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <AppSpinner
            v-else-if="fetchDiaryEntriesAction.is(States.LOADING)"
            class="m-auto p-4"
          />

          <div
            v-else
            class="px-4"
          >
            No raised entries logged.
          </div>
        </div>

        <div class="w-1/2 border border-gray-300 shadow-md rounded relative flex flex-col">
          <h2 class="text-xl font-semibold pt-4 px-4">
            Diary Entries
          </h2>

          <div
            v-if="diaryEntries.length"
            class="overflow-y-auto relative flex-1"
          >
            <div class="absolute inset-0 overflow-y-auto p-4">
              <ul class="space-y-4">
                <li
                  v-for="diaryEntry in diaryEntries"
                  :key="diaryEntry.id"
                  class="cursor-pointer"
                  @click="selectEntry(diaryEntry.id)"
                >
                  <div class="flex flex-col">
                    <h3 class="font-semibold text-lg">
                      {{ diaryEntry.createdAt.toLocaleString() }} - {{ diaryEntry.user?.name }}
                    </h3>

                    <p class="line-clamp-3 whitespace-pre">
                      {{ diaryEntry.body }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <AppSpinner
            v-else-if="fetchDiaryEntriesAction.is(States.LOADING)"
            class="m-auto px-4"
          />

          <div
            v-else
            class="px-4"
          >
            No entries logged.
          </div>
        </div>
      </div>

      <div
        v-if="!diaryEntriesStore.active"
        class="w-full flex justify-center items-center h-16 space-x-4"
      >
        <button
          type="button"
          class="px-2 py-1 bg-gray-200 rounded-full"
          data-test="prev-day"
          @click="prevDate"
        >
          <Icon
            name="chevron-left-mini"
            class="h-5 w-5"
          />
        </button>

        <AppInput
          v-model="selectedDay"
          class="px-4 py-1 border border-gray-200 rounded-full"
          type="date"
          name="selected_date"
          data-test="select-day-input"
        />

        <button
          type="button"
          class="px-2 py-1 bg-gray-200 rounded-full"
          data-test="next-day"
          @click="nextDate"
        >
          <Icon
            name="chevron-right-mini"
            class="h-5 w-5"
          />
        </button>
      </div>
    </div>

    <DiaryEntryModal
      v-if="showModal"
      :entry="selectedEntry"
      @close="showModal = false"
    />

    <DiaryFilterModal
      v-if="showFilter"
      :project-id="projectId"
      @filter="setFilter"
      @clear="clearFilter"
      @close="showFilter = false"
    />
  </div>
</template>

<script setup lang="ts">
import DiaryEntryModal from '@/components/SelfPerform/DiaryDashboard/DiaryEntryModal.vue';
import useDayQueryParamRoute from '@/composables/useDayQueryParamRoute';
import { States, useStoreApiAction } from '@/composables/useStoreApiAction';
import { falsey } from '@/helpers';
import { useDiaryEntriesStore } from '@/store/diaryEntries';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, ref, watch } from 'vue';
import DiaryFilterModal from './DiaryFilterModal.vue';

const props = defineProps<{
  projectId: string;
}>();

const diaryEntriesStore = useDiaryEntriesStore();
const projectsStore = useProjectsStore();
const fetchDiaryEntriesAction = useStoreApiAction(diaryEntriesStore.fetchDiaryEntries);
const showModal = ref(false);
const selectedEntryId = ref();

const selectedEntry = computed(() => {
  return diaryEntriesStore.models.find(selectedEntryId.value);
});

const selectEntry = (id: Id) => {
  selectedEntryId.value = id;
  showModal.value = true;
};

const newEntry = () => {
  selectedEntryId.value = undefined;
  showModal.value = true;
};

const showFilter = ref(false);
const { day: selectedDay, nextDate, prevDate } = useDayQueryParamRoute();

const allEntries = computed(() => {
  const diaryQuery = diaryEntriesStore.models.where('project_id', parseInt(props.projectId)).with('user');

  if(!diaryEntriesStore.active) {
    diaryQuery.where(
      'created_at',
      (created_at) => {
        return created_at.split('T')[0] === selectedDay.value;
      },
    );
  }

  return diaryQuery.orderBy(
    'created_at',
    'desc',
  ).get();
});

const diaryEntries = computed(() => {
  return allEntries.value.filter((entry) => !entry.raise_level);
});

const raisedDiaryEntries = computed(() => {
  return allEntries.value.filter((entry) => entry.raise_level);
});

diaryEntriesStore.resetFilter();

watch(() => [props.projectId, selectedDay.value], (newValue) => {
  if(falsey(newValue[1]) || !props.projectId) {
    return;
  }

  fetchDiaryEntriesAction.request(props.projectId, { date: newValue[1] }).catch((error) => {
    if(error?.response.status === 500 || error?.response.status === 403) {
      Swal.fire({
        icon: 'error',
        text: 'Failed to fetch diary entries.',
      });
    }
  });
}, { immediate: true });

const setFilter = () => {
  showFilter.value = false;
  diaryEntriesStore.models.flush();

  fetchDiaryEntriesAction.request(props.projectId, diaryEntriesStore.filter.form).then(() => {
    diaryEntriesStore.active = true;
  }).catch((_error) => {
    Swal.fire({
      icon: 'error',
      text: 'Failed to fetch diary entries.',
    });
  });
};

const clearFilter = () => {
  showFilter.value = false;
  diaryEntriesStore.filter.resetData();
  diaryEntriesStore.models.flush();
  diaryEntriesStore.active = false;

  fetchDiaryEntriesAction.request(props.projectId, { date: selectedDay.value }).catch((_error) => {
    Swal.fire({
      icon: 'error',
      text: 'Failed to fetch diary entries.',
    });
  });
};
</script>

<style scoped></style>
