<template>
  <div class="w-[90%] mx-auto flex items-center">
    <div class="bg-white p-2 mt-8 rounded-lg shadow-lg border border-gray-300 w-full">
      <div class="my-5 flex justify-center">
        <h1 class="text-3xl font-semibold justify-center">
          Diary Entry
        </h1>
      </div>

      <div class="items-center flex flex-col px-3">
        <div class="w-full justify-center flex flex-col space-y-4 my-2">
          <div>
            <span class="font-semibold">Date</span> {{ diaryEntry ? diaryEntry.createdAt.toLocaleString() : (new Date()).toLocaleDateString() }}
          </div>

          <form
            v-if="!diaryEntry || (recordIsToday && diaryEntry.user_id === userStore.user.id)"
            class="flex flex-col space-y-4"
            @submit.prevent="saveEntry"
          >
            <div>
              <textarea
                v-model="entryForm.form.body"
                rows="15"
                name="body"
                class="form-input resize-none"
              />

              <span
                v-if="entryForm.hasErrors('body')"
                class="text-red-600 text-sm"
              >
                {{ entryForm.getErrors('body') }}
              </span>
            </div>

            <div class="flex justify-between space-x-4">
              <button
                type="button"
                class="btn btn--secondary-blue w-1/2"
                @click="router.push({ name: 'MobileDiaryIndex' })"
              >
                Cancel
              </button>

              <button
                type="submit"
                class="btn btn--primary-blue w-1/2"
              >
                {{ diaryEntry ? 'Update' : 'Create' }}
              </button>
            </div>
          </form>

          <template v-else-if="diaryEntry">
            <div

              class="break-all whitespace-break-spaces mt-2"
            >
              {{ diaryEntry.body }}
            </div>

            <div>
              <button
                type="button"
                class="btn btn--secondary-blue w-1/2"
                @click="router.back()"
              >
                Back
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { useDiaryEntriesStore } from '@/store/diaryEntries';
import { useNavStore } from '@/store/nav';
import { useProjectsStore } from '@/store/projects';
import { useUserStore } from '@/store/user';
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  diaryId?: string;
}>();

const diaryEntriesStore = useDiaryEntriesStore();
const projectsStore = useProjectsStore();
const router = useRouter();

const project = computed(() => {
  return projectsStore.project;
});

const diaryEntry = computed(() => {
  return diaryEntriesStore.models.where('id', parseInt(props.diaryId)).first();
});

const entryForm = useFormData({
  body: '',
  raise_level: false,
});

if(props.diaryId) {
  diaryEntriesStore.fetchDiaryEntry(props.diaryId);
}

watch(diaryEntry, (newValue) => {
  if(newValue) {
    entryForm.setData(diaryEntry.value);
  }
}, { immediate: true });

const createDiaryEntryAction = useStoreApiAction(diaryEntriesStore.createDiaryEntry);
const updateDiaryEntryAction = useStoreApiAction(diaryEntriesStore.updateDiaryEntry);

const saveEntry = () => {
  if(diaryEntry.value) {
    updateDiaryEntryAction.request(diaryEntry.value.id, entryForm.form).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Entry updated.',
      }).then(() => {
        router.push({ name: 'MobileDiaryIndex' });
      });
    }).catch((error) => {
      entryForm.setErrors(error.data);
    });
  } else {
    Swal.fire({
      icon: 'question',
      title: 'Send ticket to project manager?',
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if(result.isConfirmed) {
        entryForm.form.raise_level = true;
      }

      createDiaryEntryAction.request(project.value.id, entryForm.form).then(() => {
        entryForm.resetData();

        Swal.fire({
          icon: 'success',
          title: 'Entry created.',
        }).then(() => {
          router.push({ name: 'MobileDiaryIndex' });
        });
      }).catch((error) => {
        entryForm.setErrors(error.data);
      });
    });
  }
};

const recordIsToday = computed(() => {
  return diaryEntry.value.createdAt.hasSame(DateTime.local(), 'day');
});

const userStore = useUserStore();
const navStore = useNavStore();

navStore.backRoute = { name: 'MobileDiaryIndex' };
</script>

<style scoped></style>
