<template>
  <ProjectAdminDashboard
    v-if="userStore.isRole(['project_admin', 'company_admin'])"
    :project-id="projectId"
    v-bind="$attrs"
  />

  <SiteManagerDashboard
    v-else
    :project-id="projectId"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import ProjectAdminDashboard from '@/components/SelfPerform/DiaryDashboard/ProjectAdminDashboard.vue';
import SiteManagerDashboard from '@/components/SelfPerform/DiaryDashboard/SiteManagerDashboard.vue';
import { useUserStore } from '@/store/user';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps<{
  projectId: string;
}>();

const userStore = useUserStore();
</script>

<style scoped></style>
