<template>
  <tr class="even:bg-gray-100 odd:bg-white text-right border-black border-x hover:bg-blue-100 p-2 m-2">
    <td
      class="py-2 text-left pl-3 w-80"
    >
      {{ tradeItem.name }}
    </td>

    <td class="pr-3">
      {{ australianCurrency(tradeItem.budget) }}
    </td>

    <template v-if="claim.capture_claim_amount">
      <td
        v-if="!falsey(tradeItemCost?.current_claim) && !typingClaim && canEditClaim"
        :class="{ 'cursor-pointer': canEditClaim }"
        class="bg-green-200/50"
        @click="typeClaim"
      >
        <div class="group flex items-center justify-end">
          <Icon
            name="trash"
            class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
            @click.stop.capture="removeClaim"
          />

          <Icon
            v-if="canEditClaim"
            name="edit"
            class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
          />
          {{ !falsey(tradeItemCost?.current_claim) ? australianCurrency(tradeItemCost.current_claim) : '' }}
        </div>
      </td>

      <td
        v-else-if="canEditClaim"
        class="bg-green-200/50"
      >
        <div class="w-1/2 ml-auto">
          <AppInput
            ref="claimInput"
            v-model.number="costForm.form.current_claim"
            name="current_claim"
            type="number"
            min="1"
            class="w-20 h-6"
            @blur="stopTypingClaim"
            @keyup.enter="stopTypingClaim"
            @keyup.esc="typingClaim = false"
          />
        </div>
      </td>

      <td
        v-else
        class="bg-green-200/50"
      >
        {{ !falsey(tradeItemCost?.current_claim) ? australianCurrency(tradeItemCost.current_claim) : '' }}
      </td>
    </template>

    <td
      v-if="!falsey(tradeItemCost?.current_certified) && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-green-200/50"
      @click="typeCertified"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="removeCertified"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ !falsey(tradeItemCost?.current_certified) ? australianCurrency(tradeItemCost.current_certified) : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-green-200/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedInput"
          v-model.number="costForm.form.current_certified"
          name="current_certified"
          type="number"
          min="1"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-green-200/50"
    >
      {{ !falsey(tradeItemCost?.current_certified) ? australianCurrency(tradeItemCost.current_certified) : '' }}
    </td>

    <td
      v-if="claim.capture_claim_amount"
      class="pr-3 bg-green-200/50"
      :class="{ 'text-red-600': hasVariance && variance !== 0 }"
      :title="hasVariance && variance !== 0 ? varianceComment?.body : ''"
    >
      {{ hasVariance ? australianCurrency(variance) : '' }}
    </td>

    <td class="bg-green-200/50">
      {{ australianCurrency(tradeItem.previous(claim)) }}
    </td>

    <td class="bg-green-200/50 pr-3">
      {{ australianCurrency(tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) }}
    </td>

    <template v-if="claim.capture_claim_amount">
      <td
        v-if="!falsey(tradeItemCost?.current_claim) && !typingClaim && canEditClaim"
        :class="{ 'cursor-pointer': canEditClaim }"
        class="bg-blue-300/50"
        @click="typeClaimPercent"
      >
        <div class="group flex items-center justify-end">
          <Icon
            name="trash"
            class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
            @click.stop.capture="removeClaim"
          />

          <Icon
            v-if="canEditClaim"
            name="edit"
            class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
          />
          {{ !falsey(tradeItemCost?.current_claim) ? `${australianNumber((tradeItemCost.current_claim / tradeItem.budget) * 100)}%` : '' }}
        </div>
      </td>

      <td
        v-else-if="canEditClaim"
        class="bg-blue-300/50"
      >
        <div class="w-1/2 ml-auto">
          <AppInput
            ref="claimPercentInput"
            v-model.number="currentClaimPercent"
            name="current_claim_percent"
            type="number"
            class="w-20 h-6"
            @blur="stopTypingClaim"
            @keyup.enter="stopTypingClaim"
            @keyup.esc="typingClaim = false"
          />
        </div>
      </td>

      <td
        v-else
        class="bg-blue-300/50"
      >
        {{ !falsey(tradeItemCost?.current_claim) ? `${australianNumber((tradeItemCost.current_claim / tradeItem.budget) * 100)}%` : '' }}
      </td>
    </template>

    <td
      v-if="!falsey(tradeItemCost?.current_certified) && !typingCertified && canEditCertified"
      :class="{ 'cursor-pointer': canEditCertified }"
      class="bg-blue-300/50"
      @click="typeCertifiedPercent"
    >
      <div class="group flex items-center justify-end">
        <Icon
          name="trash"
          class="group-hover:visible btn-icon-red h-5 w-5 inline-block invisible"
          @click.stop.capture="removeCertified"
        />

        <Icon
          v-if="canEditCertified"
          name="edit"
          class="group-hover:visible btn-icon-blue h-5 w-5 inline-block invisible"
        />
        {{ !falsey(tradeItemCost?.current_certified) ? `${australianNumber((tradeItemCost.current_certified / tradeItem.budget) * 100)}%` : '' }}
      </div>
    </td>

    <td
      v-else-if="canEditCertified"
      class="bg-blue-300/50"
    >
      <div class="w-1/2 ml-auto">
        <AppInput
          ref="certifiedPercentInput"
          v-model.number="currentCertifiedPercent"
          name="current_certified_percent"
          type="number"
          class="w-20 h-6"
          @blur="stopTypingCertified"
          @keyup.enter="stopTypingCertified"
          @keyup.esc="typingCertified = false"
        />
      </div>
    </td>

    <td
      v-else
      class="bg-blue-300/50"
    >
      {{ !falsey(tradeItemCost?.current_certified) ? `${australianNumber((tradeItemCost.current_certified / tradeItem.budget) * 100)}%` : '' }}
    </td>

    <td class="bg-blue-300/50">
      {{ australianNumber((tradeItem.previous(claim) / tradeItem.budget) * 100) }}%
    </td>

    <td class="bg-blue-300/50 pr-3">
      {{ australianNumber(((tradeItem.previous(claim) + (tradeItemCost?.current_certified || 0)) / tradeItem.budget) * 100) }}%
    </td>

    <td class="pr-3">
      {{ australianCurrency(Math.abs(tradeItem.budget - tradeItem.previous(claim) - (tradeItemCost?.current_certified || 0))) }}
    </td>
  </tr>
</template>

<script setup lang="ts">
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { australianCurrency, australianNumber, falsey, isNumber, roundDecimals } from '@/helpers';
import Claim, { ClaimStatus } from '@/models/Claim';
import Trade from '@/models/Trade';
import TradeItem from '@/models/TradeItem';
import { useTradeItemCostsStore } from '@/store/tradeItemCosts';
import Swal from 'sweetalert2';
import { nextTick, ref, watch } from 'vue';
import { computed } from 'vue';

const props = defineProps<{
  trade: Trade;
  claim: Claim;
  tradeItem: TradeItem;
}>();

const tradeItemCostsStore = useTradeItemCostsStore();

const tradeItemCost = computed(() => {
  return tradeItemCostsStore.models.with('comments')
    .where('trade_item_id', props.tradeItem.id)
    .where('claim_id', props.claim.id)
    .first();
});

const canEditCertified = computed(() => {
  return props.claim.status === ClaimStatus.draft;
});

const typingCertified = ref(falsey(tradeItemCost.value?.current_certified));
const certifiedInput = ref();
const certifiedPercentInput = ref();

const typeCertified = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedInput.value?.focusInput();
    });
  }
};

const typeCertifiedPercent = () => {
  if(canEditCertified.value) {
    typingCertified.value = true;

    nextTick(() => {
      certifiedPercentInput.value?.focusInput();
    });
  }
};

const canEditClaim = computed(() => {
  return props.claim.status === ClaimStatus.draft;
});

const typingClaim = ref(falsey(tradeItemCost.value?.current_claim));
const claimInput = ref();
const claimPercentInput = ref();

const typeClaim = () => {
  if(canEditClaim.value) {
    typingClaim.value = true;

    nextTick(() => {
      claimInput.value?.focusInput();
    });
  }
};

const typeClaimPercent = () => {
  if(canEditClaim.value) {
    typingClaim.value = true;

    nextTick(() => {
      claimPercentInput.value?.focusInput();
    });
  }
};

const costForm = useFormData({
  current_certified: undefined,
  current_claim: undefined,
  comment: undefined,
});

const currentCertifiedPercent = computed({
  get() {
    return roundDecimals((costForm.form.current_certified / props.tradeItem.budget) * 100) || undefined;
  },

  set(value) {
    costForm.form.current_certified = roundDecimals((value / 100) * props.tradeItem.budget);
  },
});

const currentClaimPercent = computed({
  get() {
    return roundDecimals((costForm.form.current_claim / props.tradeItem.budget) * 100) || undefined;
  },

  set(value) {
    costForm.form.current_claim = roundDecimals((value / 100) * props.tradeItem.budget);
  },
});

watch(tradeItemCost, (newValue) => {
  if(newValue) {
    costForm.setData(newValue);
  }
}, { immediate: true });

const storeTradeItemCostAction = useStoreApiAction(tradeItemCostsStore.createTradeItemCost);
const updateTradeItemCostAction = useStoreApiAction(tradeItemCostsStore.updateTradeItemCost);
const deleteTradeItemCostAction = useStoreApiAction(tradeItemCostsStore.deleteTradeItemCost);

const stopTypingClaim = async () => {
  if(
    (falsey(costForm.form.current_claim) && !tradeItemCost.value) ||
    !typingClaim.value
  ) {
    return;
  }

  if(falsey(costForm.form.current_claim) && tradeItemCost.value && falsey(tradeItemCost.value.current_certified)) {
    deleteTradeItemCostAction.request(tradeItemCost.value.id).then(() => {
      typingClaim.value = true;
    }).catch((_error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      typingClaim.value = false;
    });
  } else if(tradeItemCost.value) {
    costForm.resetData(['comment']);

    if(
      !falsey(tradeItemCost.value.current_certified) &&
      costForm.form.current_claim !== tradeItemCost.value.current_certified
    ) {
      // const comment = !tradeComment ?
      const comment = await Swal.fire({
        icon: 'warning',
        title: 'Reason for Adjustment',
        text: 'Please provide a reason for the variance between the claim and certified amounts.',
        input: 'text',
      });
      // :
      // undefined;

      costForm.form.comment = comment?.value;

      // TODO: 10/27/23 maybe stop prompting to apply to trade

      // if(!tradeComment) {
      //   await Swal.fire({
      //     icon: 'question',
      //     title: 'Apply Comment to Trade?',
      //     showConfirmButton: true,
      //     confirmButtonText: 'Yes',
      //     showDenyButton: true,
      //   }).then((result) => {
      //     if(result.isConfirmed) {
      //       costForm.form.comment_trade_item = true;
      //     }
      //   });
      // }
    }

    updateTradeItemCostAction.request(tradeItemCost.value.id, {
      current_claim: costForm.form.current_claim,
      comment: costForm.form.comment,
    })
      .then((_tradeItemCost) => {
        if(falsey(tradeItemCost.value.current_claim)) {
          typingClaim.value = true;
        } else {
          typingClaim.value = false;
        }
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid data.',
            text: error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong.',
          });
        }

        costForm.form.current_claim = tradeItemCost.value.current_claim;
        typingClaim.value = false;
      });
  } else {
    storeTradeItemCostAction.request(props.claim.id, props.tradeItem.id, costForm.form).then((_tradeItemCost) => {
      typingClaim.value = false;
    }).catch((error) => {
      console.log(error);

      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid amount.',
          text: error.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });
      }

      typingClaim.value = true;
    });
  }
};

const stopTypingCertified = async () => {
  if(
    (falsey(costForm.form.current_certified) && !tradeItemCost.value) ||
    !typingCertified.value
  ) {
    return;
  }

  if(falsey(costForm.form.current_certified) && tradeItemCost.value && falsey(tradeItemCost.value.current_claim)) {
    deleteTradeItemCostAction.request(tradeItemCost.value.id).then(() => {
      typingCertified.value = false;
    }).catch((_error) => {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
      });

      typingCertified.value = true;
    });
  } else if(tradeItemCost.value) {
    costForm.resetData(['comment']);

    if(
      !falsey(tradeItemCost.value.current_certified) &&
      costForm.form.current_claim !== tradeItemCost.value.current_certified
    ) {
      // const comment = !tradeComment ?
      const comment = await Swal.fire({
        icon: 'warning',
        title: 'Reason for Adjustment',
        text: 'Please provide a reason for the variance between the claim and certified amounts.',
        input: 'text',
      });
      // :
      // undefined;

      costForm.form.comment = comment?.value;

      // TODO: 10/27/23 maybe stop prompting to apply to trade

      // if(!tradeComment) {
      //   await Swal.fire({
      //     icon: 'question',
      //     title: 'Apply Comment to Trade?',
      //     showConfirmButton: true,
      //     confirmButtonText: 'Yes',
      //     showDenyButton: true,
      //   }).then((result) => {
      //     if(result.isConfirmed) {
      //       costForm.form.comment_trade_item = true;
      //     }
      //   });
      // }
    }

    updateTradeItemCostAction.request(tradeItemCost.value.id, {
      current_certified: costForm.form.current_certified,
      comment: costForm.form.comment,
    })
      .then((_tradeItemCost) => {
        if(falsey(tradeItemCost.value?.current_certified)) {
          typingCertified.value = true;
        } else {
          typingCertified.value = false;
        }
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid data.',
            text: error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong.',
          });
        }

        costForm.form.current_certified = tradeItemCost.value.current_certified;

        typingCertified.value = false;
      });
  } else {
    storeTradeItemCostAction.request(props.claim.id, props.tradeItem.id, costForm.form).then((tradeItemCost) => {
      console.log(tradeItemCost);
      typingCertified.value = false;
    }).catch((error) => {
      console.log(error);

      if(error.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid amount.',
          text: error.data.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong.',
        });
      }

      typingCertified.value = true;
    });
  }
};

const deleteCost = () => {
  deleteTradeItemCostAction.request(tradeItemCost.value.id).then(() => {
    costForm.form.current_certified = undefined;
    costForm.form.current_claim = undefined;
    typingCertified.value = true;
    typingClaim.value = true;
  }).catch((_error) => {
    Swal.fire({
      icon: 'error',
      title: 'Something went wrong.',
    });
  });
};

const removeClaim = () => {
  if(falsey(tradeItemCost.value.current_certified)) {
    deleteCost();
  } else {
    updateTradeItemCostAction.request(tradeItemCost.value.id, { current_claim: undefined })
      .then((tradeItemCost) => {
        console.log(tradeItemCost);
        typingClaim.value = true;
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid amount.',
            text: error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong.',
          });
        }

        costForm.form.current_claim = tradeItemCost.value.current_claim;
      });
  }
};

const removeCertified = () => {
  if(falsey(tradeItemCost.value.current_claim)) {
    deleteCost();
  } else {
    updateTradeItemCostAction.request(tradeItemCost.value.id, { current_certified: undefined })
      .then((tradeItemCost) => {
        console.log(tradeItemCost);
        typingCertified.value = true;
      }).catch((error) => {
        if(error.status === 422) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid amount.',
            text: error.data.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Something went wrong.',
          });
        }

        costForm.form.current_certified = tradeItemCost.value.current_certified;
      });
  }
};

const varianceComment = computed(() => {
  return tradeItemCost.value?.comments[0];
});

const variance = computed(() => {
  return tradeItemCost.value?.current_claim - tradeItemCost.value?.current_certified;
});

const hasVariance = computed(() => {
  return !falsey(tradeItemCost.value?.current_claim) && !falsey(tradeItemCost.value?.current_certified);
});
</script>

<style scoped></style>
