<template>
  <Modal @close="$emit('close')">
    <template #header>
      <div class="w-full text-center p-2">
        <h1 class="text-2xl">
          Filter
        </h1>
      </div>
    </template>

    <template #body>
      <div class="h-full w-full p-4 max-w-3xl">
        <form
          action=""
          class="flex flex-col space-y-4"
        >
          <div class="flex space-x-4">
            <label>
              Asset ID
              <AppInput
                v-model="plantsStore.filter.form.fleet"
                type="text"
                name="fleet"
                data-test="filter-fleet"
                class="form-input"
              />
            </label>

            <label>
              Supplier
              <AppInput
                v-model="plantsStore.filter.form.supplier_name"
                type="text"
                name="supplier_name"
                data-test="filter-supplier-name"
                class="form-input"
              />
            </label>
          </div>

          <label class="flex justify-center w-full">
            <div class="mx-auto">
              <button
                type="button"
                class="rounded-l-lg py-2 px-4 border-r border-black"
                :class="[plantsStore.filter.form.wet === true ? 'bg-gray-300' : 'bg-gray-100']"
                data-test="filter-type-wet"
                @click="plantsStore.filter.form.wet = true"
              >Hourly</button>

              <button
                type="button"
                class="rounded-r-lg py-2 px-4"
                :class="[plantsStore.filter.form.wet === false ? 'bg-gray-300' : 'bg-gray-100']"
                data-test="filter-type-dry"
                @click="plantsStore.filter.form.wet = false"
              >Daily</button>
            </div>
          </label>

          <div class="flex flex-col w-full">
            <label>
              Record date
            </label>

            <div class="flex w-full space-x-4 items-center justify-between">
              <AppInput
                v-model="plantsStore.filter.form.day_min"
                name="day_min"
                type="date"
                class="form-input"
              />

              <p>
                to
              </p>

              <AppInput
                v-model="plantsStore.filter.form.day_max"
                name="day_max"
                type="date"
                class="form-input"
              />
            </div>
          </div>

          <label>
            WBS Code

            <AppSelect
              v-model.number="plantsStore.filter.form.wbs_code_id"
              :options="project?.codes.map((code) => ({ value: code.id, label: code.code }))"
              name="wbs_code"
              data-test="wbs_code"
              class="form-input"
            />
          </label>

          <label>
            WBS Description

            <AppSelect
              v-model.number="plantsStore.filter.form.wbs_code_id"
              :options="project?.codes.map((code) => ({ value: code.id, label: code.description }))"
              name="wbs_description"
              data-test="wbs_description"
              class="form-input max-w-md"
            />
          </label>

          <label>
            Vendor Ref

            <AppTypeAhead
              v-model="plantsStore.filter.form.vendor_ref"
              name="vendor_ref"
              :suggestion-callback="getSuggestions"
              data-test="filter-vendor-ref"
              class="form-input"
            />
          </label>
        </form>
      </div>
    </template>

    <template #footer>
      <div class="w-full flex justify-end p-2 space-x-4">
        <button
          type="button"
          class="btn btn--secondary-blue"
          @click="$emit('clear')"
        >
          Clear
        </button>

        <button
          type="button"
          class="btn btn--primary-blue"
          data-test="apply-filter"
          @click="$emit('filter')"
        >
          Filter
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { usePlantDailyRecordsStore } from '@/store/plantDailyRecord';
import { usePlantsStore } from '@/store/plants';
import { useProjectsStore } from '@/store/projects';
import { computed } from 'vue';
import Modal from '../Modal.vue';

defineEmits<{
  (e: 'close');
  (e: 'filter');
  (e: 'clear');
}>();

const plantsStore = usePlantsStore();
const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.project;
});

const plantDailyRecordsStore = usePlantDailyRecordsStore();

const getSuggestions = () => {
  return plantDailyRecordsStore.search(project.value?.id, { vendor_ref: plantsStore.filter.form.vendor_ref });
};
</script>

<style scoped></style>
