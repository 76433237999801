import { dateAsYMD } from '@/helpers';
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default function() {
  const queryParams = useRoute().query.day as string;
  const router = useRouter();
  const day = ref<string>('');

  if(queryParams) {
    day.value = !DateTime.fromFormat(queryParams, 'yyyy-MM-dd').isValid ? dateAsYMD(new Date()) : queryParams;
  } else {
    day.value = dateAsYMD(new Date());
  }

  watch(day, (newValue) => {
    if(DateTime.fromFormat(newValue, 'yyyy-MM-dd').isValid) {
      router.replace({ query: { day: `${newValue}` } });
    }
  }, { immediate: true });

  const nextDate = () => {
    const selected = new Date(day.value);

    selected.setDate(selected.getDate() + 1);
    day.value = dateAsYMD(selected);
  };

  const prevDate = () => {
    const selected = new Date(day.value);

    selected.setDate(selected.getDate() - 1);
    day.value = dateAsYMD(selected);
  };

  return { day, nextDate, prevDate };
}
