<template>
  <div class="space-y-10 p-5">
    <div class="card">
      <div class="card__header">
        Details
      </div>

      <div>
        <form
          class="p-6 space-x-10 flex"
          @submit.prevent="updateProject"
        >
          <div>
            <label>
              Name
              <AppInput
                v-model="projectForm.form.name"
                name="name"
                type="text"
                data-test="name"
                class="form-input"
              />
            </label>

            <label>
              Reference
              <AppInput
                v-model="projectForm.form.reference"
                name="name"
                type="text"
                data-test="reference"
                class="form-input"
              />
            </label>

            <label class="flex items-center justify-between">
              <p>
                Track Weekends For Utilisation
              </p>

              <input
                v-model="projectForm.form.track_weekends"
                type="checkbox"
                name="track_weekends"
                class="focus:ring-0 border border-gray-400 block focus:outline-none"
              >
            </label>

            <fieldset class="mt-4">
              <legend class="font-semibold">
                Standard Shift
              </legend>

              <div class="flex space-x-4">
                <label
                  class="w-1/2"
                  title="The number of hours in a standard shift for Labour on the project"
                >
                  Labour <span class="text-sm text-gray-400">(hr)</span>

                  <AppInput
                    v-model.number="projectForm.form.labour_standard_shift"
                    name="labour_standard_shift"
                    step=".5"
                    type="number"
                    data-test="labour_standard_shift"
                    class="form-input"
                    :error="projectForm.getErrors('labour_standard_shift')"
                  />
                </label>

                <label
                  class="w-1/2"
                  title="The number of hours in a standard shift for plant item on the project"
                >
                  Plant <span class="text-sm text-gray-400">(hr)</span>

                  <AppInput
                    v-model.number="projectForm.form.plant_standard_shift"
                    name="plant_standard_shift"
                    step=".5"
                    type="number"
                    data-test="plant_standard_shift"
                    class="form-input"
                    :error="projectForm.getErrors('plant_standard_shift')"
                  />
                </label>
              </div>

              <div class="flex space-x-4 mt-4">
                <label
                  class="w-1/2"
                  title="The maximum number of hours in a standard shift for labour or plant item on the project"
                >
                  Maximum <span class="text-sm text-gray-400">(hr)</span>

                  <AppInput
                    v-model.number="projectForm.form.maximum_shift"
                    name="maximum_shift"
                    type="number"
                    step=".5"
                    data-test="maximum_shift"
                    class="form-input"
                    :error="projectForm.getErrors('maximum_shift')"
                  />
                </label>

                <label
                  class="w-1/2"
                  title="The normal start time for both labour and plant on the project"
                >
                  Start
                  <AppInput
                    v-model="projectForm.form.shift_start_at"
                    name="shift_start_at"
                    type="time"
                    data-test="shift_start_at"
                    class="form-input"
                    :error="projectForm.getErrors('shift_start_at')"
                  />
                </label>
              </div>
            </fieldset>


            <div class="flex justify-between mt-6">
              <button
                type="submit"
                class="btn btn--primary-blue"
                data-test="save-project"
              >
                Save Project Settings
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="flex w-full gap-4">
      <SupplierList
        :project-id="$props.projectId"
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SupplierList from '@/components/ProjectSettings/ProjectTab/SupplierList.vue';
import { useFormData } from '@/composables/useFormData';
import { useStoreApiAction } from '@/composables/useStoreApiAction';
import { timeForInput } from '@/helpers';
import { useProjectsStore } from '@/store/projects';
import Swal from 'sweetalert2';
import { computed, nextTick, watch } from 'vue';

const props = defineProps<{
  projectId: string;
}>();

const emits = defineEmits<{
  (e: 'dirty');
  (e: 'saved');
}>();

const projectsStore = useProjectsStore();

const project = computed(() => {
  return projectsStore.models.query().find(parseInt(props.projectId));
});

const projectForm = useFormData({
  name: '',
  labour_standard_shift: 0,
  plant_standard_shift: 0,
  maximum_shift: 0,
  shift_start_at: '',
  track_weekends: false,
  reference: '',
});

watch(project, (newValue) => {
  if(newValue) {
    projectForm.setData(newValue, ['shift_start_at']);
    projectForm.form.shift_start_at = timeForInput(newValue.shiftStartAt);

    nextTick(() => {
      projectForm.resetDirtyStatus();
    });
  }
}, { immediate: true });

watch(projectForm.isDirty, (newValue) => {
  if(newValue) {
    emits('dirty');
  } else {
    emits('saved');
  }
});

const updateProjectAction = useStoreApiAction(projectsStore.updateProject);

const updateProject = () => {
  projectForm.resetErrors();

  updateProjectAction.request(props.projectId, projectForm.form).then(() => {
    Swal.fire({
      title: 'Project Updated',
      icon: 'success',
    });

    projectForm.form.name = project.value.name;
    projectForm.form.labour_standard_shift = project.value.labour_standard_shift;
    projectForm.form.plant_standard_shift = project.value.plant_standard_shift;
    projectForm.form.maximum_shift = project.value.maximum_shift;
    projectForm.form.shift_start_at = timeForInput(project.value.shiftStartAt);
  }).catch((error) => {
    projectForm.setErrors(error.data);
  });
};
</script>

<style scoped></style>
