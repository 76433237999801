import { useFormData } from '@/composables/useFormData';
import DiaryEntry from '@/models/DiaryEntry';
import DiaryEntryService from '@/services/DiaryEntryService';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';

export const useDiaryEntriesStore = defineStore('diaryEntries', {
  state: () => ({
    filter: useFormData({
      user_id: undefined,
      text: undefined,
      name: undefined,
    }),

    active: false,
  }),

  getters: {
    models: () => useRepo(DiaryEntry),
  },

  actions: {
    resetFilter() {
      this.active = false;
      this.filter.resetData();
    },

    async fetchDiaryEntry(id: Id) {
      const [data, error] = await DiaryEntryService.show(id);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async fetchDiaryEntries(projectId: Id, form: ApiRequests['api.v1.diary-entries.index'] = {}) {
      const [data, error] = await DiaryEntryService.index(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async createDiaryEntry(projectId: Id, form: ApiRequests['api.v1.diary-entries.store']) {
      const [data, error] = await DiaryEntryService.create(projectId, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async updateDiaryEntry(id: Id, form: ApiRequests['api.v1.diary-entries.update']) {
      const [data, error] = await DiaryEntryService.update(id, form);

      if(data) {
        this.models.save(data);

        return data;
      }

      throw error;
    },

    async searchUsers(projectId: Id, form: ApiRequests['api.v1.diary-entries.users.search']) {
      const [data, error] = await DiaryEntryService.search(projectId, form);

      if(data) {
        return data;
      }

      throw error;
    },
  },
});
